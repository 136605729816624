let sliders = {
    hashes: ['intro', 'diakmunkat-vallalnal', 'mit-szeretnel-elerni', 'munkara-eretseg', 'mennyit-leget keresni', 'berkalkulator', 'hol-talalsz-diakmunkat', 'miert-jo-az-iskolaszovetseg', 'hogyan-valassz-munkat', 'jelentkezes-bekuldese', 'legyel-penge-az-interjun', 'teszt-interju', 'gratulalunk', 'mit-nem-kerhetnek-toled', 'mikor-jon-a-fizetes', 'meddig-tart-a-munka', 'vege'],
    titles: ['Diákmunkát vállalnál?', 'Diákmunkát vállalnál? Segítünk!', 'Mit szeretnél elérni?', 'Munkára érettség', 'Mennyit lehet keresni?', 'Bérkalkulátor', 'Hol találsz diákmunkát?', 'Miért jó az iskolaszövetkezet?', 'Hogyan válassz munkát?', 'A jelentkezés beküldése', 'Légy penge az interjún!', 'Vigyázzat Teszt interjú!', 'Gratulálunk!', 'Mit nem kérhet tőled a cég ahol dolgozol?', 'Mikor jön a fizetés?', 'Meddig tart a munka?', 'Gratulálunk'],
    next(step) {
        if (step > 0) {
            location.hash = sliders.hashes[step];
            $('title').html(sliders.titles[step]);
        }

        $('section').fadeOut(1000);
        $('section[data-slide="' + step + '"]').delay(500).fadeIn(1000);
        setTimeout(() => {
            sliders['slide' + step]();
        }, 700);
    },
    slide0() {

        $('section[data-slide="0"] .cover-bottom').animate({
            bottom: 0
        }, 1000);
        $('section[data-slide="0"] .dots').animate({
            opacity: 1
        }, 2000);
        $('section[data-slide="0"] .dots-left').animate({
            top: 0
        }, 12000);
        $('section[data-slide="0"] .dots-right').animate({
            bottom: 0
        }, 12000);
        $('section[data-slide="0"] .logos').delay(500).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="0"] h1, section[data-slide="0"] h4').delay(800).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="0"] .cover-sharpes').delay(1000).animate({
            opacity: 1
        }, 1000);
    },

    slide1() {
        $('section[data-slide="1"] .cover-bottom').animate({
            bottom: 0,
            opacity: 1
        }, 1000);
        $('section[data-slide="1"] .cover-right').animate({
            right: '-28%',
            opacity: 1
        }, 1000);
        $('section[data-slide="1"] .cover-right-dots').delay(400).animate({
            top: 0,
            opacity: 1
        }, 3000);
        $('section[data-slide="1"] .cover-right-sharpes').delay(700).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="1"] .cover-top').animate({
            top: '-20%',
            opacity: 1
        }, 1000);
        $('section[data-slide="1"] h2').delay(400).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="1"] p').delay(800).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="1"] .btn').delay(1500).animate({
            opacity: 1
        }, 1000);
    },

    slide2() {
        $('section[data-slide="2"] .cover-right').animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="2"] h2').delay(400).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="2"] p').delay(800).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="2"] #my-goal').delay(1000).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="2"] .goal-icons.desktop .goal-icon').each(function (i) {
            $(this).delay(800 + (i * 300)).animate({
                opacity: 1
            }, 1000);
        });

        $('section[data-slide="2"] .goal-icons.mobil .goal-icon').each(function (i) {
            $(this).delay(800 + (i * 300)).animate({
                opacity: 1
            }, 1000);
        });
        $('section[data-slide="2"] .btn-next').delay(3000).animate({
            opacity: 1
        }, 1000);
    },

    slide3() {
        if($(window).width() > 992){
            methods.skillStart();
        }
        $('section[data-slide="3"] .cover-right').animate({
            opacity: 1,
            right: 0
        }, 1000);
        $('section[data-slide="3"] .cover-arrow').delay(400).animate({
            opacity: 1,
            bottom: 0
        }, 1000);
        $('section[data-slide="3"] .cover-awards').delay(600).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="3"] h2').delay(400).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="3"] p').delay(800).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="3"] .skills-container').delay(1000).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="3"] .stars svg').each(function (i) {
            $(this).delay(1200 + (i * 300)).animate({
                opacity: 1
            }, 1000);
        });

    },
    slide4() {
        methods.skillStop();
        $('section[data-slide="4"] .cover-right.desktop').animate({
            opacity: 1,
            right: 0
        }, 1000);
        $('section[data-slide="4"] .cover-right.mobil').addClass('animate-end');
        $('section[data-slide="4"] h2').delay(400).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="4"] button').delay(700).animate({
            opacity: 1
        }, 1000);
    },
    slide5() {
        $('section[data-slide="5"] .form-container').delay(700).animate({
            opacity: 1
        }, 1400);
        $('section[data-slide="5"] button').delay(1700).animate({
            opacity: 1
        }, 1000);
    },
    slide6() {
        $('section[data-slide="6"] .cover-right').animate({
            opacity: 1,
            right: 0,
            top: 0
        }, 1000);
        $('section[data-slide="6"] h2').delay(400).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="6"] img.row-1').delay(700).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="6"] p.row-1').delay(850).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="6"] img.row-2').delay(1000).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="6"] p.row-2').delay(1150).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="6"] a').delay(1500).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="6"] button').delay(2000).animate({
            opacity: 1
        }, 1000);
    },
    slide7() {
        $('section[data-slide="7"] .cover-right').animate({
            opacity: 1,
            right: 0
        }, 1000);
        $('section[data-slide="7"] .cover-right-dots').delay(400).animate({
            opacity: 1,
            bottom: 0
        }, 1000);
        $('section[data-slide="7"] .cover-right-sharpes').delay(1000).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="7"] .cover-top').animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="7"] h2').delay(400).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="7"] .row-1').delay(600).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="7"] .row-2').delay(800).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="7"] .row-3').delay(1000).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="7"] button').delay(1500).animate({
            opacity: 1
        }, 1000);
    },
    slide8() {
        $('section[data-slide="8"] h2').delay(400).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="8"] p').delay(600).animate({
            opacity: 1
        }, 1000);

        $('section[data-slide="8"] .carousel').each(function (i) {
            $(this).delay(600 + (i * 300)).animate({
                opacity: 1
            }, 1000);
        });

        $('section[data-slide="8"] button').delay(5000).animate({
            opacity: 1
        }, 1000);
    },
    slide9() {
        $('section[data-slide="9"] .cover').animate({
            right: 0
        }, 1000);
        $('section[data-slide="9"] h2').delay(400).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="9"] p').delay(600).animate({
            opacity: 1
        }, 1000);

        $('section[data-slide="9"] .nav').delay(800).animate({
            opacity: 1
        }, 1000);

        $('section[data-slide="9"] .item').each(function (i) {
            $(this).delay(1000 + (i * 300)).animate({
                opacity: 1
            }, 1000);
        });
    },
    slide10() {
        $('section[data-slide="10"] h2').delay(400).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="10"] p').delay(600).animate({
            opacity: 1
        }, 1000);

        $('section[data-slide="10"] .nav').delay(800).animate({
            opacity: 1
        }, 1000);

        $('section[data-slide="10"] .item').each(function (i) {
            $(this).delay(1000 + (i * 300)).animate({
                opacity: 1
            }, 1000);
        });
    },
    slide11() {
        $('section[data-slide="11"] .boss').animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="11"] .page-1 h2').delay(400).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="11"] .page-2 h2').delay(400).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="11"] .page-1 .alert-img').delay(600).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="11"] .page-1 .comment-box').delay(800).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="11"] .page-1 .btn-next').delay(1000).animate({
            opacity: 1
        }, 1000);
    },
    slide12() {
        $('section[data-slide="12"] h2').delay(400).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="12"] .row-1').delay(600).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="12"] .row-2').delay(800).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="12"] .awards').delay(600).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="12"] button').delay(1000).animate({
            opacity: 1
        }, 1000);
    },
    slide13() {
        $('section[data-slide="13"] h2').delay(400).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="13"] .cover').delay(400).animate({
            right: 0,
            opacity: 1
        }, 1000);
        $('section[data-slide="13"] .row-1').delay(600).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="13"] .row-2').delay(800).animate({
            opacity: 1
        }, 1000);

        $('section[data-slide="13"] .side-left .btn-white').each(function (i) {
            $(this).delay(1000 + (i * 300)).animate({
                opacity: 1
            }, 1000);
        });

        $('section[data-slide="13"] .side-right .btn-white').each(function (i) {
            $(this).delay(1200 + (i * 350)).animate({
                opacity: 1
            }, 1000);
        });

        setTimeout(() => {
            $('section[data-slide="13"] .btn-next').animate({
                opacity: 1
            }, 1000).attr('disabled', 'disabled');
        }, 3000);

    },
    slide14() {
        $('section[data-slide="14"] h2').delay(400).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="14"] p').delay(600).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="14"] .calendar-15').delay(800).animate({
            opacity: 1
        }, 500);
        $('section[data-slide="14"] .calendar').delay(1000).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="14"] button').delay(1800).animate({
            opacity: 1
        }, 1000);
    },
    slide15() {
        $('section[data-slide="15"] h2').delay(400).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="15"] p').delay(600).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="15"] img').delay(800).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="15"] button').delay(1200).animate({
            opacity: 1
        }, 1000);
    },
    slide16() {
        $('section[data-slide="16"] .cover-bottom').animate({
            bottom: 0
        }, 1000);
        $('section[data-slide="16"] .dots').animate({
            opacity: 1
        }, 2000);
        $('section[data-slide="16"] .dots-left').animate({
            top: 0
        }, 12000);
        $('section[data-slide="16"] .dots-right').animate({
            bottom: 0
        }, 12000);
        $('section[data-slide="16"] h2').delay(800).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="16"] h4').delay(1000).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="16"] p').delay(1200).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="16"] .cat-1').delay(1400).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="16"] .cat-2').delay(1400).animate({
            opacity: 1
        }, 1000);
    },
};

let methods = {
    goal: {
        ct: 0
    },
    carousel: {
        s9: {
            timer: false,
            width: 0,
            left: 0,
            direction: 'left'
        },
        s10: {
            timer: false,
            width: 0,
            left: 0,
            direction: 'left'
        },
    },
    skills: {
        direction: 'minus',
        go: 'stop',
        timer: false
    },
    selectGoal(goal) {
        if (this.goal.ct < 1 && !$('section[data-slide="2"] .goal-icon[data-goal="' + goal + '"]').hasClass('selected')) {
            $('section[data-slide="2"] #my-goal .placeholder').hide();
            $('section[data-slide="2"] #my-goal div[data-goal="' + goal + '"]').fadeIn(500);
            $('section[data-slide="2"] .goal-icon[data-goal="' + goal + '"]').addClass('selected');
            $('section[data-slide="2"] .btn-next').removeAttr('disabled').removeClass('btn--inactive').css({
                'z-index': 100
            });
            this.goal.ct++;
        }


    },
    removeGoal(goal) {
        this.goal.ct--;
        $('section[data-slide="2"] #my-goal .placeholder').show();
        $('section[data-slide="2"] #my-goal div[data-goal="' + goal + '"]').hide();
        $('section[data-slide="2"] .goal-icon[data-goal="' + goal + '"]').removeClass('selected')

        if (this.goal.ct <= 0) {
            $('section[data-slide="2"] .btn-next').attr('disabled', 'disabled').addClass('btn--inactive').css({
                'z-index': 0
            });
        }
    },

    selectedMyLaw(self) {
        if (!self.hasClass('selected')) {
            self.addClass('selected').addClass(self.data('type') == 'good' ? 'success' : 'failed');
        }

        $('section[data-slide="13"] .btn-next').removeAttr('disabled');
    },
    interviewStart() {
        $('section[data-slide="11"] .page-1').fadeOut(500);
        $('section[data-slide="11"] .page-2').fadeIn(500);
        $('section[data-slide="11"] .interview_man').show().delay(300).animate({
            opacity: 1,
            left: 0
        }, 1000);
        this.interviewNext(1);
    },
    interviewNext(question) {
        if (question > 4) {
            sliders.next(12);
            return;
        }
        $('section[data-slide="11"] .page-2 .left-side .counter span').text(question);
        $('section[data-slide="11"] .page-2 .comment-box').css({
            opacity: 0
        });
        $('section[data-slide="11"] .page-2 .thought-box').fadeOut(400);
        $('section[data-slide="11"] .page-2 .answers').fadeOut(400);

        setTimeout(() => {
            $('section[data-slide="11"] .page-2 .status-bar').addClass('q-' + question);
        }, 400);
        $('section[data-slide="11"] .page-2 .q-' + question).delay(600).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="11"] .page-2 .a-' + question).show().delay(1000).animate({
            opacity: 1
        }, 1000);
        $('section[data-slide="11"] .page-2 .a-' + question + ' li').each(function (i) {
            $(this).delay(1200 + (i * 350)).animate({
                opacity: 1
            }, 1000);
        });
    },

    interviewAnswerSelected(self, question) {
        if (!($('section[data-slide="11"] .page-2 .a-' + question + ' li').hasClass('good') || $('section[data-slide="11"] .page-2 .a-' + question + ' li').hasClass('wrong'))) {
            if (self.data('type') == 'good') {
                self.addClass('good');
            } else {
                self.addClass('wrong');
            }

            $('section[data-slide="11"] .page-2 .comment-box.q-' + question).fadeOut(400);
            $('section[data-slide="11"] .page-2 .thought-box .text').html(self.data('thought'));
            $('section[data-slide="11"] .page-2 .thought-box').fadeIn(600);

            var _this = this;
            setTimeout(() => {
                _this.interviewNext(++question);
            }, 3000);
        }
    },

    carousel9(direction) {
        this.carousel.s9.width = ($('section[data-slide="9"] .carousel .inner').outerWidth(true) - $(window).width());

        if (direction == 'left' && Math.abs(methods.carousel.s9.left) > methods.carousel.s9.width) {
            return;
        } else if (direction == 'right' && Math.abs(methods.carousel.s9.left) < 40) {
            return;
        }
    
        if (direction == 'left') {
            methods.carousel.s9.left -= $('section[data-slide="9"] .carousel .inner .item').outerWidth(true);
        } else {
            methods.carousel.s9.left += $('section[data-slide="9"] .carousel .inner .item').outerWidth(true);
        }
        $('section[data-slide="9"] .carousel .inner').animate({
            left: methods.carousel.s9.left + 'px'
        }, 1000);
    },
    carousel10(direction) {
        this.carousel.s10.width = ($('section[data-slide="10"] .carousel .inner').outerWidth(true) - $(window).width());

        if (direction == 'left' && Math.abs(methods.carousel.s10.left) > methods.carousel.s10.width) {
            return;
        } else if (direction == 'right' && Math.abs(methods.carousel.s10.left) < 40) {
            return;
        }
    
        if (direction == 'left') {
            methods.carousel.s10.left -= $('section[data-slide="10"] .carousel .inner .item').outerWidth(true);
        } else {
            methods.carousel.s10.left += $('section[data-slide="10"] .carousel .inner .item').outerWidth(true);
        }
        $('section[data-slide="10"] .carousel .inner').animate({
            left: methods.carousel.s10.left + 'px'
        }, 1000);
    },

    unnumber_format(number) {
        number = parseInt(("" + number).replace(/\s/gi, '').trim());
        return number > 0 ? number : 0;
    },

    currency_format(number, currency) {
        number = this.unnumber_format(number);

        if (isNaN(number)) {
            return NaN;
        }

        var num = Math.round(number) + "";
        num = num.split(".", 2);
        var is_minus = 0;
        if (parseInt(num[0]) < 0) {
            num[0] = num[0].substring(1, num[0].length);
            is_minus = 1;
        }
        var len = num[0].length;
        var place = new Array;
        for (var i = 3; i <= len + 2; i += 3) {
            place[place.length] = num[0].substring(len - i, len - i + 3);
        }
        var value = "";
        for (var i = place.length - 1; i >= 0; i--) {
            value += place[i] + " ";
        }
        value = value.replace(/,$/, "");
        value = value.trim();

        if (is_minus) {
            value = '-' + value;
        }

        return value;
    },

    calculator(type) {
        let vat = 0.665;
        let week = 4;
        let net_hour = this.unnumber_format($('section[data-slide="5"] #net').val());
        let hour = this.unnumber_format($('section[data-slide="5"] #hour').val());
        let net_monthly = this.unnumber_format($('section[data-slide="5"] #monthly_net').val());
        let gross_hour = this.unnumber_format($('section[data-slide="5"] #gross').val());
        let gross_monthly = this.unnumber_format($('section[data-slide="5"] #monthly_gross').val());

        if (type == 'gross') {
            net_hour = gross_hour * vat;
        }
        if (type == 'net') {
            gross_hour = net_hour / vat;
        }
        net_monthly = net_hour * hour * week;
        gross_monthly = gross_hour * hour * week;

        $('section[data-slide="5"] #net').val(this.currency_format(net_hour));
        $('section[data-slide="5"] #gross').val(this.currency_format(gross_hour));

        if ((gross_hour > 0 || net_hour > 0) && hour > 0) {
            $('section[data-slide="5"] #monthly_net').val(this.currency_format(net_monthly));
            $('section[data-slide="5"] #monthly_gross').val(this.currency_format(gross_monthly));
        }else{
            $('section[data-slide="5"] #monthly_net').val('');
            $('section[data-slide="5"] #monthly_gross').val('');
        }

    },
    change_work_type(price){
        $('section[data-slide="5"] #gross').val(price);
        this.calculator('gross');
        if(price == 0){
            $('section[data-slide="5"] #gross').val('');
            $('section[data-slide="5"] #gross').trigger('focus');
        }
    },
    change_hour_count(){
        console.log($('section[data-slide="5"] input[name="weekday"]:checked'));
        let weekend_8_hours = $('section[data-slide="5"] #weekend-8:checked').val() != undefined ? parseInt($('section[data-slide="5"] #weekend-8:checked').val()) : 0;
        let weekend_4_hours = $('section[data-slide="5"] #weekend-4:checked').val() != undefined ? parseInt($('section[data-slide="5"] #weekend-4:checked').val()) : 0;
        let weekday_8_hours = $('section[data-slide="5"] #weekday-8:checked').val() != undefined ? parseInt($('section[data-slide="5"] #weekday-8:checked').val()) : 0;
        let weekday_4_hours = $('section[data-slide="5"] #weekday-4:checked').val() != undefined ? parseInt($('section[data-slide="5"] #weekday-4:checked').val()) : 0;
        $('section[data-slide="5"] #hour').val(weekend_8_hours + weekend_4_hours + weekday_8_hours + weekday_4_hours);
        
        this.calculator('gross');
    },

    showCoords(event) {
        if (this.skills.go != 'stop-center') {
            var div = document.querySelector('section[data-slide="3"] .skills-container .arrow');
            var divOffset = this.skillOffset(div);
            var y = event.clientY;
            this.skills.go = 'start';
            if (y < divOffset.top) {
                this.skills.direction = 'plus';
            }

            if (y > divOffset.top) {
                this.skills.direction = 'minus';
            }
        }
    },

    skillStart() {
        this.skills.timer = setInterval(function () {
            if (methods.skills.go.search('stop') == -1) {
                methods.moveSkills();
            }

        }, 500)
    },

    skillStop(){
        clearInterval(this.skills.timer);
    },

    skillOffset(el) {
        var rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return {
            top: rect.top + scrollTop,
            left: rect.left + scrollLeft
        }
    },
    moveSkills() {
        let center = 0;
        if (this.skills.direction == 'minus') {
            center = $('section[data-slide="3"] .skills .item.center').index() + 1;
        } else {
            center = $('section[data-slide="3"] .skills .item.center').index() - 1;
        }
    
        if (center < 0) {
            this.skills.direction = 'stop';
            return;
        }
    
        if (center >= $('section[data-slide="3"] .skills .item').length) {
            this.skills.direction = 'stop';
            return;
    
        }
    
        let start = center - 2;
    
        for (let i = 0; i < $('section[data-slide="3"] .skills .item').length; i++) {
            $('section[data-slide="3"] .skills .item').eq(i).removeClass('center').removeClass('bottom-1').removeClass('bottom-2').removeClass('top-2').removeClass('top-1').removeClass('hide-top').removeClass('hide-bottom').removeClass('move');
            $('section[data-slide="3"] .skills .item').eq(i).find('button').removeAttr('disabled');
            if (i < start) {
                $('section[data-slide="3"] .skills .item').eq(i).addClass('hide-top');
                $('section[data-slide="3"] .skills .item').eq(i).find('button').attr('disabled', 'disabled');
            } else if (i == start) {
                $('section[data-slide="3"] .skills .item').eq(i).addClass('top-2');
                $('section[data-slide="3"] .skills .item').eq(i).find('button').attr('disabled', 'disabled');
            } else if (i == start + 1) {
                $('section[data-slide="3"] .skills .item').eq(i).addClass('top-1');
                setTimeout(() => {
                    $('section[data-slide="3"] .skills .item').eq(i).addClass('move');
                }, 500);
            } else if (i == start + 2) {
                $('section[data-slide="3"] .skills .item').eq(i).addClass('center');
            } else if (i == start + 3) {
                $('section[data-slide="3"] .skills .item').eq(i).addClass('bottom-1');
                setTimeout(() => {
                    $('section[data-slide="3"] .skills .item').eq(i).addClass('move');
                }, 500);
            } else if (i == start + 4) {
                $('section[data-slide="3"] .skills .item').eq(i).addClass('bottom-2');
                $('section[data-slide="3"] .skills .item').eq(i).find('button').attr('disabled', 'disabled');
            } else {
                $('section[data-slide="3"] .skills .item').eq(i).addClass('hide-bottom');
                $('section[data-slide="3"] .skills .item').eq(i).find('button').attr('disabled', 'disabled');
            }
    
        }
    }

};


(function ($) {
    $('.money').mask("# ##0", {
        reverse: true
    });
    if (location.hash != '') {
        for (var i in sliders.hashes) {
            if ('#' + sliders.hashes[i] == location.hash) {
                sliders.next(i);
                break;
            }
        }
    } else {
        sliders.next(0);

        setTimeout(() => {
            sliders.next(1)
        }, 7000);
    }

    $('section[data-slide="9"] .carousel .nav .left').on('click', function () {
        methods.carousel9('right');
    });

    $('section[data-slide="9"] .carousel .nav .right').on('click', function () {
        methods.carousel9('left');
    });

    $('section[data-slide="10"] .carousel .nav .left').on('click', function () {
        methods.carousel10('right');
    });

    $('section[data-slide="10"] .carousel .nav .right').on('click', function () {
        methods.carousel10('left');
    });

    var selected_stars = 0;

    $('section[data-slide="3"] .skills').on('click', '.item button:not(.selected)', function () {
        if (selected_stars < 5) {
            $(this).addClass('selected');
            if ($(this).data('type') == 'good') {
                $(this).addClass('good');
                $('section[data-slide="3"] .stars svg').eq(selected_stars++).addClass('good');
            } else {
                $(this).addClass('wrong');
                $('section[data-slide="3"] .stars svg').eq(selected_stars++).addClass('wrong');
            }

            if (selected_stars > 2) {
                $('section[data-slide="3"] .btn-next').removeAttr('disabled');
            } else {
                $('section[data-slide="3"] .btn-next').attr('disabled', 'disabled');
            }
        }
    });

    var weekend_checked = false; 
    $('section[data-slide="5"]').on('click', '[name="weekend"]', function () {
        let checked = true;
        if(weekend_checked == $(this).val()){
            checked = false;
        }

        $('section[data-slide="5"] [name="weekend"]').prop('checked', false);
        $('section[data-slide="5"] [name="weekend"]').removeAttr('checked');
        if(checked){
            $(this).prop('checked', true);
            $(this).attr('checked', 'checked');
            weekend_checked = $(this).val();
        }else{
            weekend_checked = false;
        }
    });

    var weekday_checked = false; 
    $('section[data-slide="5"]').on('click', '[name="weekday"]', function () {
        let checked = true;
        if(weekday_checked == $(this).val()){
            checked = false;
        }

        $('section[data-slide="5"] [name="weekday"]').prop('checked', false);
        $('section[data-slide="5"] [name="weekday"]').removeAttr('checked');
        if(checked){
            $(this).prop('checked', true);
            $(this).attr('checked', 'checked');
            weekday_checked = $(this).val();
        }else{
            weekday_checked = false;
        }
    });

    var calculater_delay;
    $('section[data-slide="5"]').on('keyup', '.edit', function () {
        let _self = $(this);
        calculater_delay = setTimeout(function () {
            if (_self.attr('name') == 'net') {
                methods.calculator('net');
            } else if (_self.attr('name') == 'gross') {
                methods.calculator('gross');
            } else if (_self.attr('name') == 'hour') {
                methods.calculator('hour');
            }
        }, 1000);
    });

    $('section[data-slide="3"] .skills').on('mouseover', '.item.center', function () {
        methods.skills.go = 'stop-center';
    });

    $('section[data-slide="3"] .skills').on('mouseout', '.item.center', function () {
        methods.skills.go = 'start';
    });

})(jQuery);
